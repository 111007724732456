<template>
    
    <div class='app-container'>
      
        <el-page-header @back="goBack" class="header">
            <template slot="content">
                邮件定制：{{first}}-{{second}}
            </template>
        </el-page-header>
        <el-divider class="margin10"></el-divider>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="评审邀请邮件" name="first">
                <el-container>
                    <el-aside width='600px'>
                        <el-card class="left">
                            <el-divider content-position="left">论文信息</el-divider>
                            <el-row :gutter="40">
                                <el-col v-for="item in paperInfos" :key="item.name" :span="8">
                                    <div class="box" @click="copyHandle(item.name)">
                                        ${ {{item.name}} }
                                    </div>
                                    <div class="box-text">
                                        {{item.description}}
                                    </div>
                                </el-col>
                            </el-row>
                            <!-- <el-divider content-position="left">专家信息</el-divider>
                            <el-row :gutter="40">
                                <el-col v-for="item in expertInfos" :key="item.name" :span="8">
                                    <div class="box" @click="copyHandle(item.name)">
                                    ${ {{item.name}} }
                                    </div>
                                    <div class="box-text">
                                        {{item.description}}
                                    </div>
                                </el-col>
                            </el-row> -->
                        </el-card>
                    </el-aside>

                    <el-main style="padding:0px;" class="right">
                        <el-card>
                            <el-divider content-position="left">邮件预览</el-divider>
                        </el-card>
                        <div>
                            <quill-editor  ref="myQuillEditor" v-model="emailList.emailTemplate_yaoshen" :options="editorOption" class="my-quill-editor" key="myQuillEditor"></quill-editor>
                        </div>
                        <el-button type="primary" @click="open(emailList)" style='margin:20px;float:right'>更新</el-button>
                    </el-main>
                </el-container>
            </el-tab-pane>

            
            <el-tab-pane label="确认接审邮件" name="second">
                <el-container>
                    <el-aside width='600px'>
                        <el-card class="left">
                            <el-divider content-position="left">论文信息</el-divider>
                            <el-row :gutter="40">
                                <el-col v-for="item in paperInfos" :key="item.name" :span="8">
                                    <div class="box" @click="copyHandle(item.name)">
                                        ${ {{item.name}} }
                                    </div>
                                    <div class="box-text">
                                        {{item.description}}
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-aside>
                    <el-main style="padding:0px;" class="right">
                        <el-card>
                            <el-divider content-position="left">邮件预览</el-divider>
                        </el-card>
                        <div>
                            <quill-editor  ref="myQuillEditor1" v-model="emailList.emailTemplate_jieshen" :options="editorOption" class="my-quill-editor" key="myQuillEditor1"></quill-editor>
                        </div>
                        <el-button type="primary" @click="open(emailList)" style='margin:20px;float:right'>更新</el-button>
                    </el-main>
                </el-container>
            </el-tab-pane>

            <el-tab-pane label="过期确认邮件" name="third">
                <el-container>
                    <el-aside width='600px'>
                        <el-card class="left">
                            <el-divider content-position="left">论文信息</el-divider>
                            <el-row :gutter="40">
                                <el-col v-for="item in paperInfos" :key="item.name" :span="8">
                                    <div class="box" @click="copyHandle(item.name)">
                                        ${ {{item.name}} }
                                    </div>
                                    <div class="box-text">
                                        {{item.description}}
                                    </div>
                                </el-col>
                            </el-row>
                            
                        </el-card>
                    </el-aside>
                    <el-main style="padding:0px;" class="right">
                        <el-card>
                            <el-divider content-position="left">邮件预览</el-divider>
                        </el-card>
                        <div>
                            <quill-editor  ref="myQuillEditor2" v-model="emailList.emailTemplate_guoqi" :options="editorOption" class="my-quill-editor" key="myQuillEditor2"></quill-editor>
                        </div>
                        <el-button type="primary" @click="open(emailList)" style='margin:20px;float:right'>更新</el-button>
                    </el-main>
                </el-container>
            </el-tab-pane>

            <el-tab-pane label="智能催审邮件" name="fourth">
                <el-container>
                    <el-aside width='600px'>
                        <el-card class="left">
                            <el-divider content-position="left">论文信息</el-divider>
                            <el-row :gutter="40">
                                <el-col v-for="item in paperInfos" :key="item.name" :span="8">
                                    <div class="box" @click="copyHandle(item.name)">
                                        ${ {{item.name}} }
                                    </div>
                                    <div class="box-text">
                                        {{item.description}}
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-aside>
                    <el-main style="padding:0px;" class="right">
                        <el-card>
                            <el-divider content-position="left">邮件预览</el-divider>
                        </el-card>
                        <div>
                            <quill-editor  ref="myQuillEditor3" v-model="emailList.emailTemplate_cuishen" :options="editorOption" class="my-quill-editor" key="myQuillEditor3"></quill-editor>
                        </div>
                        <el-button type="primary" @click="open(emailList)" style='margin:20px;float:right'>更新</el-button>
                    </el-main>
                </el-container>
            </el-tab-pane>

            <el-tab-pane label="逾期催审邮件" name="fifth">
                <el-container>
                    <el-aside width='600px'>
                        <el-card class="left">
                            <el-divider content-position="left">论文信息</el-divider>
                            <el-row :gutter="40">
                                <el-col v-for="item in paperInfos" :key="item.name" :span="8">
                                    <div class="box" @click="copyHandle(item.name)">
                                        ${ {{item.name}} }
                                    </div>
                                    <div class="box-text">
                                        {{item.description}}
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-aside>
                    <el-main style="padding:0px;" class="right">
                        <el-card>
                            <el-divider content-position="left">邮件预览</el-divider>
                        </el-card>
                        <div>
                            <quill-editor  ref="myQuillEditor4" v-model="emailList.emailTemplate_cuishenEnd" :options="editorOption" class="my-quill-editor" key="myQuillEditor4"></quill-editor>
                        </div>
                        <el-button type="primary" @click="open(emailList)" style='margin:20px;float:right'>更新</el-button>
                    </el-main>
                </el-container>
            </el-tab-pane>

            <el-tab-pane label="提交确认邮件" name="sixth">
                <el-container>
                    <el-aside width='600px'>
                        <el-card class="left">
                            <el-divider content-position="left">论文信息</el-divider>
                            <el-row :gutter="40">
                                <el-col v-for="item in paperInfos" :key="item.name" :span="8">
                                    <div class="box" @click="copyHandle(item.name)">
                                        ${ {{item.name}} }
                                    </div>
                                    <div class="box-text">
                                        {{item.description}}
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-aside>
                    <el-main style="padding:0px;" class="right">
                        <el-card>
                            <el-divider content-position="left">邮件预览</el-divider>
                        </el-card>
                        <div>
                            <quill-editor  ref="myQuillEditor5" v-model="emailList.emailTemplate_submit" :options="editorOption" class="my-quill-editor" key="myQuillEditor5"></quill-editor>
                        </div>
                        <el-button type="primary" @click="open(emailList)" style='margin:20px;float:right'>更新</el-button>
                    </el-main>
                </el-container>
            </el-tab-pane>

            <el-tab-pane label="打款通知邮件" name="seventh">
                <el-container>
                    <el-aside width='600px'>
                        <el-card class="left">
                            <el-divider content-position="left">论文信息</el-divider>
                            <el-row :gutter="40">
                                <el-col v-for="item in paperInfos" :key="item.name" :span="8">
                                    <div class="box" @click="copyHandle(item.name)">
                                        ${ {{item.name}} }
                                    </div>
                                    <div class="box-text">
                                        {{item.description}}
                                    </div>
                                </el-col>
                            </el-row>
                            
                        </el-card>
                    </el-aside>
                    <el-main style="padding:0px;" class="right">
                        <el-card>
                            <el-divider content-position="left">邮件预览</el-divider>
                        </el-card>
                        <div>
                            <quill-editor  ref="myQuillEditor6" v-model="emailList.emailTemplate_dakuan" :options="editorOption" class="my-quill-editor" key="myQuillEditor6"></quill-editor>
                        </div>
                        <el-button type="primary" @click="open(emailList)" style='margin:20px;float:right'>更新</el-button>
                    </el-main>
                </el-container>
            </el-tab-pane>
        </el-tabs>
        <el-dialog
            title="邮件模板确认"
            :visible.sync="dialogVisible"
            width="75%"
            >
            <div v-html="emailTemplateDialog"></div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="postEmailTemplate(emailList), dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>

       
    </div>
</template>

<script>
    import {
        Form
    } from 'vxe-table';
    import {
        
    } from 'vxe-table';
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';

    import { quillEditor, Quill } from 'vue-quill-editor';

    // import { QuillEditor } from 'vue-quill-editor'

    export default {
        components: {
          quillEditor,
        },
        data() {
            return {
                activeName: 'first',
                emailTemplateDialog: '',
                // emailTemplate:'初始化',
                editorOption: {
                    // 占位配置
                    placeholder: '请输入...',
                    height: '800px',
                    modules: {
                        // 配置工具栏
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线 
                            // [{size:["10px", "14px", false, "18px", "20px"]}], //字体大小
                            ['blockquote', 'code-block'], // 引用  代码块
                            [{ header: 1 }, { header: 2 }], // 1、2 级标题
                            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                            [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                            [{ indent: '-1' }, { indent: '+1' }], // 缩进
                            [{ direction: 'rtl' }], // 文本方向
                            ['link', 'image', 'video'], // 链接、图片、视频
                            [{ align: [] }], // 添加居中按钮
                            [{ color: [] }], // 文字颜色按钮
                        ],
                    }
                },

                uploadModel: false,
                paperInfos: [],
                expertInfos: [],
                numberTable: [],
                totalTable: [],
                singleTable: [],
                mulTable: [],
                textTable: [],
                commentTable: [],
                curMark: {
                    title: '数值型',
                    type: 'number',
                    field: 'field'
                },
                emailList: {
                    emailId: '',
                    // institution: '南京理工大学',
                    // degreeType: '专业型硕士',
                    emailTemplate: '初始化',
                    emailTemplate_yaoshen: '',
                    emailTemplate_jieshen: '',
                    emailTemplate_guoqi: '',
                    emailTemplate_cuishen: '',
                    emailTemplate_cuishenEnd: '',
                    emailTemplate_submit: '',
                    emailTemplate_dakuan: '',
                    emailType:'yaoshen',
                },
                first:'',
                second:'',
                dialogVisible:false

            }
        },
        created() {
            this.emailId = this.$route.params.id;
            this.first = this.$store.state.custom.institution.first;
            this.second = this.$store.state.custom.institution.second;
            // this.getReviewPaperInfos();
            // this.getReviewExpertInfos();
            this.getEmailPaperInfos();
            // this.getEmailExpertInfos();
            this.getEmailTemplate();
        },
        methods: {  
            open(emailList) {
                //原字符串
                let ystring="";
                if (emailList.emailType == 'yaoshen') {
                    ystring=emailList.emailTemplate_yaoshen;
                }
                else if(emailList.emailType == 'jieshen'){
                    ystring=emailList.emailTemplate_jieshen;
                }
                else if(emailList.emailType == 'guoqi'){
                    ystring=emailList.emailTemplate_guoqi;
                }
                else if(emailList.emailType == 'cuishen'){
                    ystring=emailList.emailTemplate_cuishen;
                }
                else if(emailList.emailType == 'cuishenEnd'){
                    ystring=emailList.emailTemplate_cuishenEnd;
                }
                else if(emailList.emailType == 'submit'){
                    ystring=emailList.emailTemplate_submit;
                }
                else if(emailList.emailType == 'dakuan'){
                    ystring=emailList.emailTemplate_dakuan;
                }
                // ystring=emailList.emailTemplate;
                // console.log(ystring);
                const tstring=ystring.replace(/  /g,"&nbsp;&nbsp;");
                const dialogTemplate=tstring.replace("${expertName}","张三").replace("${institution}",this.first).replace(/\${studentMajor}/g,"计算机科学与技术").replace("${paperType}",this.second).replace("${paperTitle}","《标题》").replace(/\${endTime}/g,"2023年12月20日").replace("${price}","200.0").replace("${acceptUrl}","http://acceptUrl").replace("${rejectUrl}","http://rejectUrl").replace("${addition}","系统检测到您还未进入论文评审页面，烦请及时登录平台查看并评审。");
                this.emailTemplateDialog = dialogTemplate;
                // console.log(this.emailTemplateDialog);
                this.dialogVisible = true;
            },
            handleClick(tab, event) {
                console.log(tab, event);
                if (tab.name == 'first') {
					this.emailList.emailType = 'yaoshen';
                    this.getEmailTemplate();
                    // this.emailList.emailTemplate_yaoshen=this.emailList.emailTemplate;
				} else if(tab.name == 'second') {
					this.emailList.emailType = 'jieshen';
                    // this.emailList.emailTemplate_jieshen=this.emailList.emailTemplate;
                    this.getEmailTemplate();
				}else if(tab.name == 'third') {
					this.emailList.emailType = 'guoqi';
                    // this.emailList.emailTemplate_guoqi=this.emailList.emailTemplate;
                    this.getEmailTemplate();
				}else if(tab.name == 'fourth') {
					this.emailList.emailType = 'cuishen';
                    // this.emailList.emailTemplate_cuishen=this.emailList.emailTemplate;
                    this.getEmailTemplate();
				}else if(tab.name == 'fifth') {
					this.emailList.emailType = 'cuishenEnd';
                    // this.emailList.emailTemplate_cuishenEnd=this.emailList.emailTemplate;
                    this.getEmailTemplate();
				}else if(tab.name == 'sixth') {
					this.emailList.emailType = 'submit';
                    // this.emailList.emailTemplate_submitn=this.emailList.emailTemplate;
                    this.getEmailTemplate();
				}else if(tab.name == 'seventh') {
					this.emailList.emailType = 'dakuan';
                    // this.emailList.emailTemplate_dakuan=this.emailList.emailTemplate;
                    this.getEmailTemplate();
				}
            },
            // 获取论文信息
            getEmailPaperInfos() {
                this.$api.schoolCustom.getEmailPaperInfos()
                    .then(res => {
                        if (res.data.code == 200) {
                            this.paperInfos = res.data.data;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            // 获取专家信息
            // getEmailExpertInfos() {
            //     this.$api.schoolCustom.getEmailExpertInfos()
            //         .then(res => {
            //             if (res.data.code == 200) {
            //                 this.expertInfos = res.data.data;
            //             }
            //         }).catch(err => {
            //             this.$message.warning("服务器维护！");
            //         })
            // },
            // 获取邮件模板
            // getEmailTemplate() {
            //     this.$api.schoolCustom.getEmailTemplate()
            //         .then(res => {
            //             if (res.status == 200) {
            //                 // console.log("222222");
            //                 // console.log(this.emailTemplate);
            //                 // console.log(res.data);
            //                 this.emailList.emailTemplate = res.data;
            //             }
            //         }).catch(err => {
            //             this.$message.warning("服务器维护！");
            //         })
            // },
            // 获取邮件模板
            getEmailTemplate() {
                let params = {
                    emailId: this.emailId,
                    emailType: this.emailList.emailType
                };
                this.$api.schoolCustom.getEmailTemplate(params)
                    .then(res => {
                        if (res.status == 200) {
                            if (this.emailList.emailType == 'yaoshen') {
                                this.emailList.emailTemplate_yaoshen = res.data;
                                this.emailList.emailTemplate = res.data;
                            }
                            else if(this.emailList.emailType == 'jieshen'){
                                this.emailList.emailTemplate_jieshen = res.data;
                                this.emailList.emailTemplate = res.data;
                            }
                            else if(this.emailList.emailType == 'guoqi'){
                                this.emailList.emailTemplate_guoqi = res.data;
                                this.emailList.emailTemplate = res.data;
                            }
                            else if(this.emailList.emailType == 'cuishen'){
                                this.emailList.emailTemplate_cuishen = res.data;
                                this.emailList.emailTemplate = res.data;
                            }
                            else if(this.emailList.emailType == 'cuishenEnd'){
                                this.emailList.emailTemplate_cuishenEnd = res.data;
                                this.emailList.emailTemplate = res.data;
                            }
                            else if(this.emailList.emailType == 'submit'){
                                this.emailList.emailTemplate_submit = res.data;
                                this.emailList.emailTemplate = res.data;
                            }
                            else if(this.emailList.emailType == 'dakuan'){
                                this.emailList.emailTemplate_dakuan = res.data;
                                this.emailList.emailTemplate = res.data;
                            }
                            // this.emailList.emailTemplate_yaoshen = res.data;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },

            //更新邮件模板
            postEmailTemplate(emailList) {
                // console.log(emailList.emailType);
                if (emailList.emailType == 'yaoshen') {
                    emailList.emailTemplate=emailList.emailTemplate_yaoshen;
                }
                else if(emailList.emailType == 'jieshen'){
                    emailList.emailTemplate=emailList.emailTemplate_jieshen;
                }
                else if(emailList.emailType == 'guoqi'){
                    emailList.emailTemplate=emailList.emailTemplate_guoqi;
                }
                else if(emailList.emailType == 'cuishen'){
                    emailList.emailTemplate=emailList.emailTemplate_cuishen;
                }
                else if(emailList.emailType == 'cuishenEnd'){
                    emailList.emailTemplate=emailList.emailTemplate_cuishenEnd;
                }
                else if(emailList.emailType == 'submit'){
                    emailList.emailTemplate=emailList.emailTemplate_submit;
                }
                else if(emailList.emailType == 'dakuan'){
                    emailList.emailTemplate=emailList.emailTemplate_dakuan;
                }
                //原字符串
                const ystring=emailList.emailTemplate;
                //字符串替换函数.replace
                const tstring=ystring.replace(/  /g,"&nbsp;&nbsp;");
                
                //输出替换后的字符串tstring
                // console.log(tstring)
                emailList.emailTemplate = tstring
                
                let params = {
                    emailId: this.emailId,
                    // institution: emailList.institution,
                    // degreeType: emailList.degreeType,
                    emailTemplate: emailList.emailTemplate,
                    emailType: emailList.emailType
                };
                console.log(params);
                this.$api.schoolCustom.postEmailTemplate(params)
                    .then(res => {
                        // console.log(res);
                        // console.log(params);
                        if (res.data.code == 200) {
                            this.$message.success("更新成功！");
                        } else {
                            this.$message.warning("更新失败！");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            
            // getReviewInfo() {
            //     let params = {
            //         templateId: this.templateId
            //     };
            //     this.$api.reviewCustom.getReviewInfo(params)
            //         .then(res => {
            //             if (res.data.code == 200) {
            //                 let tem = res.data.data;
            //                 this.numberTable = tem['0'] ? tem['0'] : [];
            //                 this.singleTable = tem['2'] ? tem['2'] : [];
            //                 this.mulTable = tem['3'] ? tem['3'] : [];
            //                 this.totalTable = tem['4'] ? tem['4'] : [];
            //                 this.textTable = tem['5'] ? tem['5'] : [];
            //                 this.commentTable = tem['6'] ? tem['6'] : [];
            //             }
            //         }).catch(err => {
            //             this.$message.warning("服务器维护");
            //         })
            // },
            // uploadHandle() {
            //     this.uploadModel = true;
            // },
            // cfmInsertReivewTypeHandle() {
            //     if (this.$refs.templateFile.uploadFiles.length < 1) {
            //         this.$message.warning("请选择模板文件");
            //         return;
            //     }
            //     const loading = this.$loading({
            //         lock: true,
            //         text: '上传中',
            //         spinner: 'el-icon-loading',
            //         background: 'rgba(0, 0, 0, 0.7)'
            //     });
            //     let rawFile = this.$refs.templateFile.uploadFiles[0];
            //     let params = new FormData();
            //     params.append("templateId", this.templateId);
            //     params.append("file", rawFile.raw);
            //     this.$api.reviewCustom.postUploadPatternFile(params)
            //         .then(res => {
            //             loading.close();
            //             if (res.data.code == 200) {
            //                 this.$message.success("上传成功！");
            //                  this.uploadModel = false;
            //             } else {
            //                 this.$message.warning("上传失败！");
            //             }

            //         })
            //         .catch(err => {});
            // },
            // downloadHandle() {

            // },
            // testHandle() {

            // },
            copyHandle(name) {
                let field = '${' + name + '}';
                if (navigator.clipboard && window.isSecureContext) {
                    this.$message.success(field + '复制成功')
                    return navigator.clipboard.writeText(field)
                } else {
                    // 创建text area
                    const textArea = document.createElement('textarea')
                    textArea.value = field
                    // 使text area不在viewport，同时设置不可见
                    textArea.style.position = "absolute";
                    textArea.style.opacity = 0;
                    textArea.style.left = "-999999px";
                    textArea.style.top = "-999999px";
                    document.body.appendChild(textArea)
                    textArea.focus()
                    textArea.select()
                    this.$message.success(field + '复制成功1')
                    return new Promise((res, rej) => {
                        // 执行复制命令并移除文本框
                        document.execCommand('copy') ? res() : rej()
                        textArea.remove()
                    })
                }
            },
            goBack() {
                this.$router.push({
                    name: 'EmailMenu'
                })
            },
            //合并表格
            // mergeMethod({
            //     row,
            //     _rowIndex,
            //     column,
            //     _columnIndex,
            //     visibleData
            // }) {
            //     let firstIndex = row['firstIndex'];
            //     if (column.property == 'firstIndex') {
            //         let prevRow = visibleData[_rowIndex - 1];
            //         let nextRow = visibleData[_rowIndex + 1];
            //         if (prevRow && prevRow['firstIndex'] == firstIndex) {
            //             return {
            //                 rowspan: 0,
            //                 colspan: 0
            //             };
            //         } else {
            //             let countRowspan = 1;
            //             let countColspan = 1;
            //             while (nextRow && nextRow['firstIndex'] == firstIndex) {
            //                 nextRow = visibleData[++countRowspan + _rowIndex]
            //             }
            //             if (row.type == 5 && _columnIndex == 1) {
            //                 countColspan = 2;
            //             }
            //             if (countRowspan >= 1) {
            //                 return {
            //                     rowspan: countRowspan,
            //                     colspan: countColspan
            //                 };
            //             }
            //         }
            //     }
            // },
        }

    }
</script>

<style scoped>
    .left {
        /* width: 600px; */
        height: calc(100vh - 185px);
        padding: 0px 20px;
        overflow: auto;
    }

    .left::-webkit-scrollbar {
        width: 0 !important
    }

    .right {
        min-width: 600px;
        margin-left: 20px;
        height: calc(100vh - 185px);
        overflow: auto;
    }

    .right::-webkit-scrollbar {
        width: 0 !important
    }

    .box {
        width: 146.6px;
        background-color: aliceblue;
        height: 60px;
        text-align: center;
        
        line-height: 60px;
        white-space:nowrap;
        text-overflow: ellipsis;
        border-radius: 10px;
        overflow: hidden;
    }

    .box-text {
        padding-top: 10px;
        margin-bottom: 20px;
        text-align: center;
        font-size: 14px;
    }

    .box-table {
        padding: 10px;
        background-color: aliceblue;
    }

    .comment-box {
        border: 1px solid #e8eaec;
        height: 100px;
        line-height: 100px;
        text-align: center;
        background-color: aliceblue;
    }

    .txtareaCom {
        border: 1px solid #e8eaec;
        border-top: 0px;
        padding: 10px;
        color: rgb(160, 160, 160);
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
        font-size: 14px;
    }

    /* .txtarea .el-textarea__inner {
        border: 1px solid rgb(143, 143, 143);
        border-top: 0px;
        border-radius: 0px;
    } */
</style>

<style>
    .margin10.el-divider--horizontal {
        margin: 10px 0px;
    }
    /* 编辑器高度及背景色 */
    ::v-deep .ql-editor {
        min-height: 300px;
        background-color: #fff;
    }
</style>